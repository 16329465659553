<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Institutes')"
      :subtitle="$t('Structure')"
      icon="mdi-account"
      :header-action="openNewInstitute"
      :header-action-text="$t('wsu.structure.institute.add')"
      :no-content-condition="items.length === 0"
      :no-content-text="$t('wsu.structure.institute.no_content')"
      :no-content-button-action="openNewInstitute"
      :no-content-button-text="$t('wsu.structure.institute.add')"
      fill-height
  >

    <template #default>
      <ws-data-table
          :items="items"
          :headers="headers"
          class="mt-5"
          :context-actions-select="contextActionsSelect"
          @itemSelect="selectedItem = $event"
          :row-action="selectEntity"
      >
        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>

      <ws-dialog
          v-model="displayDialog"
          :title="$t(!editInstitute ? 'wsu.structure.institute.add' :  'wsu.structure.institute.edit')"
          @save="addInstitute"
          @delete="deleteInstitute"
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          :show-delete="editInstitute"
      >

        <ws-text-field
            class="mt-6"
            v-model="entityData.name"
            :label="$t('wsu.structure.institute.name.title')"
            :placeholder="$t('wsu.structure.institute.name.placeholder')"
            :error="nameError"
            @input="nameError = false"
        />

      </ws-dialog>

      <ws-dialog
          v-model="displayDeleteDialog"
          @delete="deleteInstitute"
          :title="$t('wsu.structure.institute.delete')"
          display-confirm-delete
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          close-delete
      />

    </template>

  </sub-page>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "specialties",
  data() {
    return {
      items : [],
      displayDialog : false,
      entityData : {},
      editInstitute : false,
      selectedItem : {},
      displayDeleteDialog : false,
      nameError : false
    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang']),
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' }
      ]
    },
    contextActionsSelect() {
      return [
        { text : this.$t('Edit') ,
          value : 'edit' , icon : 'mdi-pencil' ,
          action : () => { this.openEditInstitute() } },
        { text : this.$t('Delete') ,
          value : 'delete' ,
          icon : 'mdi-delete-outline' ,
          action : () => { this.displayDeleteDialog = true }
        }
      ]
    }
  },
  methods : {
    ...mapActions('structure' , [ 'GET_INSTITUTES' , 'ADD_INSTITUTE' , 'DELETE_INSTITUTE']),

    selectEntity(item) {
      this.$store.state.structure.returnEntityType.push(this.$store.state.structure.selectedEntityType)
      this.$store.state.structure.returnEntityId.push(this.$store.state.structure.selectedEntityId)
      this.$store.state.structure.returnEntityName.push(this.$store.state.structure.selectedEntityName)

      this.$store.state.structure.selectedEntityType = 'institute'
      this.$store.state.structure.selectedEntityId = item.uuid
      this.$store.state.structure.selectedEntityName = item.name
      this.$router.push(this.businessDashLink(`structure/faculties?entity=institute&uuid=${item.uuid}&name=${item.name}`))
    },
    async addInstitute() {

      if ( !this.entityData.name ) {
        this.nameError = true
        return this.notify(this.$t('wsu.structure.institute.name.error') , 'warning')
      }

      this.entityData.lang = this.selectedLang
      let result = await this.ADD_INSTITUTE(this.entityData)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }
      if ( !this.editInstitute ) {
        this.items.push(result)
        this.notify(this.$t('wsu.structure.institute.created') , 'success')
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if (index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
        this.notify(this.$t('ChangesSaved'))
      }

      this.displayDialog = false
    },
    async deleteInstitute() {
      let result = await this.DELETE_INSTITUTE(this.selectedItem.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid )
      if (index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.notify(this.$t('wsu.structure.institute.deleted') )
      this.displayDeleteDialog = false
      this.displayDialog = false
    },
    openNewInstitute() {
      this.editInstitute = false
      this.entityData = {}
      this.displayDialog = true
    },
    openEditInstitute() {
      this.entityData = this.COPY(this.selectedItem)
      this.editInstitute = true
      this.displayDialog = true
    },

    async initPage() {
      let result = await this.GET_INSTITUTES()
      this.items = result ? result.institutes : []
    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>